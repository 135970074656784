/**
 * = PRINT styles
 *
 * - Imported in global.css (http://www.phpied.com/delay-loading-your-print-css/)
 * - Taken straight from: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L197
 *
 * @TODO:
 * - Add to this?
 *
 * ========================================================================= */

/* stylelint-disable declaration-no-important  */

@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  p:first-line,
  div:first-line,
  blockquote:first-line,
  li:first-line {
    background: transparent !important;
    color: #000 !important; /* Black prints faster:
								   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
    font-size: 13px;
  }

  a.c-link--cover {
    &[href]:after {
      position: relative;
      word-wrap: break-word;
      inset: unset;
    }
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  /*
	 * Don't show links that are fragment identifiers,
	 * or use the `javascript:` pseudo protocol
	 */

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
	 * Printing Tables:
	 * http://css-discuss.incutio.com/wiki/Printing_Tables
	 */

  .c-table--responsive {
    width: auto;
  }

  table {
    tr {
      th,
      td {
        width: auto !important;
        min-width: auto !important;
        font-size: 16px !important;
      }
    }

    h3 {
      font-size: 23px;
      margin-bottom: 8px;
    }

    h4 {
      font-style: 19px !important;
    }
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  /**
	 * HIDE certain elements completely
	 */

  .c-header,
  .c-site-search,
  .c-search-cart_container,
  .c-standard-banner,
  .c-footer,
  .c-social-icons,
  .c-call-us,
  .c-search-drop,
  .c-toolbar,
  .c-btn,
  .c-product-thumbnails__wrapper,
  .quick-nav,
  #components,
  #accessories,
  #resources,
  .u-noprint,
  #c-back-to-top,
  .c-featured-full,
  .reveal-above,
  .reveal-below,
  .c-helper-full,
  #c-chat-feature,
  .c-pagination-wrapper,
  .c-mailing-list,
  .c-helper-row,
  .c-mega-search,
  .c-article-image-title,
  .c-pagination,
  .addthis_inline_share_toolbox,
  .c-featured-news-events,
  .c-breadcrumb,
  .c-cookie,
  .c-alert,
  .c-header--items,
  .o-sidebar,
  .u-print-none,
  .c-sidebar-nav--mobile,
  #__bs_notify__ {
    display: none !important;
  }
}
